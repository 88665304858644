// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth,RecaptchaVerifier,signInWithPhoneNumber, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyABlI72trCp1MhsllkGjtoxHVrtoa6KntU",
  authDomain: "ayanachintya.firebaseapp.com",
  projectId: "ayanachintya",
  storageBucket: "ayanachintya.appspot.com",
  messagingSenderId: "232217240922",
  appId: "1:232217240922:web:26b38f35d8d8213bb43f63",
  measurementId: "G-D1HB6RHEZB",
  databaseURL: "https://ayanachintya-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const auth = getAuth();

export { auth };

/*

// Request permission to get the FCM token
const requestFCMToken = async () => {
  try {
    await Notification.requestPermission(); // Request notification permissions
    const currentToken = await getToken(messaging, { vapidKey: 'BC0Nlg7sLT0ERWeKupUbQjPmRL2zXkZ29eAuYkzq5EVBFClmVpfTCBFQLy2KhNfPAoO1B_PBmNAVyfjhMCcmprQ' });
    if (currentToken) {
      console.log('FCM Token:', currentToken);
      // Send the token to your server and update the UI if necessary
      // ...
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('Error getting FCM token:', err);
  }
};

export { messaging, getToken, requestFCMToken, onMessage, auth };
*/

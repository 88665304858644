import {
    PhoneAuthProvider,
    RecaptchaVerifier,
    signInWithCredential,
    signInWithPhoneNumber,
  } from "firebase/auth";
  import { useEffect, useState } from "react";
  import { auth } from "./firebase";
  
  const OtpVerification = ({ phoneNumber}) => {
    const [otp, setOtp] = useState("");
    const [verificationId, setVerificationId] = useState("");
  
    const setUpRecaptcha = () => {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
    };
  
    const onSendOtp = async () => {
      // Ensure that the phone number is valid before sending OTP
      if (!phoneNumber) {
        console.error("Invalid phone number");
        return;
      }
  
      if (!window.recaptchaVerifier) {
        setUpRecaptcha();
      }
  
      try {
        const result = await signInWithPhoneNumber(
          auth,
          `+${phoneNumber}`,
          window.recaptchaVerifier
        );
        setVerificationId(result.verificationId);
        console.log("OTP sent successfully");
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
    };
  
    const onVerify = async () => {
      if (!verificationId || !otp) {
        console.error("Invalid verification ID or OTP");
        return;
      }
  
      try {
        const credential = PhoneAuthProvider.credential(verificationId, otp);
        await signInWithCredential(auth, credential);
        console.log("Successfully signed in with OTP");
      } catch (error) {
        console.error("Error signing in with OTP:", error);
      }
    };
  
    useEffect(() => {
      setUpRecaptcha();
    }, []);
  
    return (
      <div className="otpVerification">
        <input
          type="number"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          maxLength={6}
          placeholder="OTP"
        />
        <div id="recaptcha-container"></div>
        <button onClick={onSendOtp}>Send OTP</button>
        <button onClick={onVerify}>Verify</button>
      </div>
    );
  };
  
  export default OtpVerification;
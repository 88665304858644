import React, { useState, useEffect } from 'react';
import logo from './ayana.svg';
import './App.css';
import './firebase.js';
import { getDatabase, ref, set, serverTimestamp } from 'firebase/database';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import OtpVerification from "./OtpVerification";
const ngeohash = require('ngeohash');

// Initialize Firebase authentication and database
const auth = getAuth();
const database = getDatabase();

function App() {
  const [inputValue, setInputValue] = useState('');
  const [user, setUser] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [userGeohash, setUserGeohash] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  // Set up anonymous authentication
  useEffect(() => {
    signInAnonymously(auth)
      .then((userCredential) => {
        setUser(userCredential.user);
      })
      .catch((error) => {
        console.error('Error signing in anonymously:', error);
      });
  }, []);

  useEffect(() => {
    if (!user) return;

    // Function to fetch user's address from GPS coordinates
    const fetchUserAddress = async () => {
      console.log('fetching');
      try {
        // Get user's current position
        const position = await getCurrentPosition();

        // Reverse geocode to get the address
        const address = await reverseGeocode(position.coords.latitude, position.coords.longitude);

        // Set the user's address state
        setUserAddress(address);

        // Set GeoFire location
        const userRef = ref(database, `users/${user.uid}/location`);
        set(userRef, {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          updatedAt: serverTimestamp()
        })
        .then(() => {
          console.log('Location added to Firebase');

          // Generate geohash
          const geohash = ngeohash.encode(position.coords.latitude, position.coords.longitude, 12); // 12 is the precision
          setUserGeohash(geohash);
        })
        .catch((error) => {
          console.error('Error adding location to Firebase:', error);
        });

      } catch (error) {
        console.error("Error getting user's address:", error);
      }
    };

    fetchUserAddress();
  }, [user]);

  // Helper function to get current position
  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  // Helper function for reverse geocoding
  const reverseGeocode = async (latitude, longitude) => {
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=YOUR_API_KEY`);
    const data = await response.json();
    console.log(data);
    if (data.results && data.results.length > 0) {
      return data.results[0].formatted_address;
    } else {
      throw new Error('No address found for the provided coordinates.');
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user) {
      const userRef = ref(database, `users/${user.uid}/profile`);
      set(userRef, {
        bio: inputValue,
        updatedAt: serverTimestamp()
      })
      .then(() => {
        alert('Profile updated successfully!');
      })
      .catch((error) => {
        alert('Error updating profile: ' + error.message);
      });
    } else {
      alert('Please sign in to update your profile.');
    }
  };

  // Function to show geohash and user's latitude and longitude
  const showGeohashAndLocation = async () => {
    try {
      // Get user's current position
      const position = await getCurrentPosition();

      // Generate geohash
      const geohash = ngeohash.encode(position.coords.latitude, position.coords.longitude, 12); // 12 is the precision

      // Display geohash, latitude, and longitude
      setUserGeohash({geohash, lat: position.coords.latitude, long: position.coords.longitude});
      console.log(`Geohash: ${geohash}`);
      console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
    } catch (error) {
      console.error('Error getting geohash and user location:', error);
    }
  };

 // Function to authenticate with fingerprint
const authenticateWithFingerprint = async () => {
  try {
    // Generate challenge and user ID
    const challenge = generateChallenge();
    const userID = 'example_user_id'; // Replace with actual user ID

    // Encode challenge and user ID as byte strings
    const encoder = new TextEncoder();
    const encodedChallenge = encoder.encode(challenge);
    const encodedUserID = encoder.encode(userID);

    // Create credential using Web Authentication API
    const credential = await navigator.credentials.create({
      publicKey: {
        challenge: encodedChallenge,
        rp: {
          name: 'Your Relying Party Name',
          id: window.location.hostname // Use current domain as relying party ID
        },
        user: {
          id: encodedUserID,
          name: 'username',
          displayName: 'User Display Name'
        },
        pubKeyCredParams: [
          {
            type: 'public-key',
            alg: -7 // For example, indicating ES256 algorithm
          }
        ],
        // Add other necessary options as needed
      }
    });

    // Check if the authentication was successful
    if (credential) {
      // Authentication succeeded, proceed with your app logic
      console.log('Fingerprint authentication successful');
      // Display authentication data
      console.log('Auth data:', credential);
    } else {
      // Authentication failed
      setErrorMessage('Fingerprint authentication failed');
    }
  } catch (error) {
    console.error('Fingerprint authentication error:', error);
    setErrorMessage('Error occurred during fingerprint authentication');
  }
};

// Generate a random challenge
const generateChallenge = () => {
  const array = new Uint8Array(32); // 32 bytes for example
  window.crypto.getRandomValues(array);
  return array;
};



  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          The silent space to take the world from Internet to Innernet.
        </p>
        <div>
          <h2>OTP Verification</h2>
          <div>
            <label>Phone Number:</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="918144356767"
            />
          </div>
          <OtpVerification phoneNumber={phoneNumber} />
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Update your bio"
          />
          <button type="submit">submit</button>
        </form>
        <button onClick={showGeohashAndLocation}>My Location</button>
        <button onClick={authenticateWithFingerprint}>Authenticate with Fingerprint</button>
        {errorMessage && <p>{errorMessage}</p>}
        {userGeohash && (
          <div>
            <h2>Your Geohash:</h2>
            <p>{userGeohash.geohash}</p>
            <p>{userGeohash.lat} {userGeohash.long}</p>
          </div>
        )}
        {userAddress && (
          <div>
            <h2>Your Address:</h2>
            <p>{userAddress}</p>
         
            </div>
        )}
      </header>
    </div>
  );
}

export default App;
